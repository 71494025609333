import axios from 'axios';

const PATH = '/cultivation_groups';

export const GroupsService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  search: async params => (await axios.get(`${PATH}/search`, { params })).data,
  archive: id => axios.delete(`${PATH}/${id}`),
  create: async payload => await axios.post(PATH, payload),
  update: async (id, payload) => await axios.patch(`${PATH}/${id}`, payload),
  updateBatch: async payload => await axios.patch(`${PATH}`, payload),
  rebalance: async (id, payload) => {
    await axios.patch(`${PATH}/${id}`, payload);
  },
  forecasting: async facility_id =>
    (
      await axios.get(`${PATH}/forecasting`, {
        params: {
          facility_id,
        },
      })
    ).data,
  refreshEvents: async ({ group_id, ...payload }) =>
    await axios.post(`${PATH}/${group_id}/refresh-events`, payload),
  deployRecipeChanges: async (id, payload) =>
    await axios.post(`${PATH}/${id}/deploy-recipe-changes`, payload),
  recalculateTraysArea: async id =>
    await axios.post(`${PATH}/${id}/recalculate-trays-area`),
};
