import axios from 'axios';

const PATH = '/device_configs';
const subcollection_registers = 'registers';

export const DevicesService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  create: async payload => await axios.post(PATH, payload),
  update: async payload => await axios.put(PATH, payload),
  delete: async id => await axios.delete(`${PATH}/${id}`),
  REGISTERS: {
    update: async (deviceId, register) =>
      await axios.put(
        `${PATH}/${deviceId}/${subcollection_registers}`,
        register
      ),
    get: async (deviceId, registerId) =>
      await axios.get(
        `${PATH}/${deviceId}/${subcollection_registers}/${registerId}`
      ),
    list: async deviceId =>
      (await axios.get(`${PATH}/${deviceId}/${subcollection_registers}`)).data,
    create: async payload =>
      await axios.post(
        `${PATH}/${payload.id}/${subcollection_registers}`,
        payload
      ),
    delete: async (deviceId, registerId) =>
      await axios.delete(
        `${PATH}/${deviceId}/${subcollection_registers}/${registerId}`
      ),
  },
};
