import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';

const ConfirmAlert = ({
  isOpen,
  onClose,
  title,
  content,
  renderContent,
  onConfirm,
  defaultInternalState,
  denyLabel = 'Close',
  confirmLabel = 'Confirm',
  hideConfirm = false,
}) => {
  const [internalState, setInternalState] = useState(defaultInternalState);

  useEffect(() => {
    setInternalState(defaultInternalState);
  }, [isOpen, defaultInternalState]);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ boxShadow: 'none' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        {content ? (
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        ) : null}
        {renderContent ? (
          <Box pt="4">{renderContent({ internalState, setInternalState })}</Box>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 'none' }}>
        <Button variant="outlined" onClick={onClose}>
          {denyLabel}
        </Button>
        {!hideConfirm && (
          <Button variant="contained" onClick={() => onConfirm(internalState)}>
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAlert;
