import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';

const devConfig = {
  apiKey: 'AIzaSyA14zo_LAl5m6hE4mRf715hVpzLoFhb5eY',
  authDomain: 'gg-dev-e25fa.firebaseapp.com',
  projectId: 'gg-dev-e25fa',
  storageBucket: 'gg-dev-e25fa.appspot.com',
  messagingSenderId: '317893771647',
  appId: '1:317893771647:web:0a53e8ace0e6c93d475a4a',
  measurementId: 'G-SX71TBYHGY',
};
const prodConfig = {
  apiKey: 'AIzaSyBFGzRUhtx8nQA4DCQWJB47kYh50ITkeQQ',
  authDomain: 'gg-io-prod.firebaseapp.com',
  projectId: 'gg-io-prod',
  storageBucket: 'gg-io-prod.appspot.com',
  messagingSenderId: '735778723662',
  appId: '1:735778723662:web:7e6dd80bc7e25ef284701f',
};

if (process.env.REACT_APP_ENV === 'prod') {
  firebase.initializeApp(prodConfig);
} else if (process.env.REACT_APP_ENV === 'dev') {
  firebase.initializeApp(devConfig);
} else {
  // local debugger uses the dev authentication (so it is the same as dev)
  firebase.initializeApp(devConfig);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
firestore.settings({ ignoreUndefinedProperties: true });

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

//can have to popup others too (Twitter, etc)
//export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const signInWithGoogle = () =>
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      //google access token, can use it to access the Google API
      // var token = result.credential.accessToken;
      //the signed-in user info
      // var user = result.user;
    })
    .catch(function (error) {
      //handle errors here
      // var errorCode = error.code;
      // var errorMessage = error.message;
      //the email of the user's account used
      // var email = error.email;
      //the firebase.auth.AuthCredential type that was used
      // var credential = error.credential;
    });

export default firebase;
