import { lazy } from 'react';
import { PrivateRoute } from '@components/common/PrivateRoute';

const TaskTemplates = lazy(() => import('@pages/tasks/TaskTemplates'));
const Tasks = lazy(() => import('@pages/tasks/Tasks'));
const TaskLoadBalancing = lazy(() => import('@pages/tasks/TasksLoadBalancing'));

export const tasksRoutes = [
  {
    path: '/tasks/task-templates',
    exact: true,
    component: TaskTemplates,
    route: PrivateRoute,
    allowedPermission: 'TASKS_TASK_TEMPLATES_TAB',
  },
  {
    path: '/tasks/task-list',
    exact: true,
    component: Tasks,
    route: PrivateRoute,
    allowedPermission: 'TASKS_TASKS_TAB',
  },
  {
    path: '/tasks/task-load-balancing',
    exact: true,
    component: TaskLoadBalancing,
    route: PrivateRoute,
    name: 'Task Load Balancing',
    allowedPermission: 'TASKS_TASK_LOAD_BALANCING_TAB',
  },
];
