import { lazy } from 'react';
import { PrivateRoute } from '../components/common/PrivateRoute';

const StrainCatalogPage = lazy(() =>
  import('../pages/performance/StrainCatalogPage')
);
const GroupsPerformancePage = lazy(() =>
  import('../pages/performance/GroupsPerformancePage')
);
const ProcessingPerformancePage = lazy(() =>
  import('../pages/performance/ProcessingPerformancePage')
);

export const performanceRoutes = [
  {
    path: '/performance/strain-catalog',
    exact: true,
    component: StrainCatalogPage,
    route: PrivateRoute,
    allowedPermission: 'PERFORMANCE_STRAIN_CATALOG_TAB',
  },
  {
    path: '/performance/cultivation-groups',
    exact: true,
    component: GroupsPerformancePage,
    route: PrivateRoute,
    allowedPermission: 'PERFORMANCE_CULTIVATION_GROUPS_TAB',
  },
  {
    path: '/performance/processing',
    exact: true,
    component: ProcessingPerformancePage,
    route: PrivateRoute,
    allowedPermission: 'PERFORMANCE_PROCESSING_TAB',
  },
];
