import { lazy } from 'react';
import { PrivateRoute } from '../components/common/PrivateRoute';

const EnvironmentalZoneTrends = lazy(() =>
  import('../pages/trends/EnvironmentalZonesTrendsPage')
);
const IrrigationZoneTrends = lazy(() =>
  import('../pages/trends/IrrigationZonesTrendsPage')
);
const WaterQualityTrends = lazy(() =>
  import('../pages/trends/WaterQualityTrendsPage')
);

export const trendsRoutes = [
  {
    path: '/trends/environmental_zones',
    name: 'Environmental Zone Trends',
    component: EnvironmentalZoneTrends,
    route: PrivateRoute,
    allowedPermission: 'TRENDS_ENVIRONMENTAL_TRENDS_TAB',
  },
  {
    path: '/trends/irrigation_zones',
    name: 'Irrigation Zone Trends',
    component: IrrigationZoneTrends,
    route: PrivateRoute,
    allowedPermission: 'TRENDS_IRRIGATION_ZONE_TRENDS_TAB',
  },
  {
    path: '/trends/water_quality',
    name: 'Water Quality Trends',
    component: WaterQualityTrends,
    route: PrivateRoute,
    allowedPermission: 'TRENDS_WATER_QUALITY_TRENDS_TAB',
  },
];
