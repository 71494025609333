import { PrivateRoute } from '../components/common/PrivateRoute';

import { Redirect } from 'react-router-dom';

export const miscRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview/facility" />,
    route: PrivateRoute,
  },
];
