import { lazy } from 'react';
import { PrivateRoute } from '../components/common/PrivateRoute';

const Forecasting = lazy(() =>
  import('../pages/forecasting/ProductionForecastingPage')
);
const PlantMovementPage = lazy(() =>
  import('../pages/forecasting/PlantMovementPage')
);
const CloneProductionPage = lazy(() =>
  import('../pages/forecasting/CloneProductionPage')
);

export const forecastingRoutes = [
  {
    path: '/forecasting',
    exact: true,
    component: Forecasting,
    route: PrivateRoute,
    allowedPermission: 'FORECASTING_FORECASTING_TAB',
  },
  {
    path: '/forecasting/plant-movement',
    exact: true,
    component: PlantMovementPage,
    route: PrivateRoute,
    allowedPermission: 'FORECASTING_PLANT_MOVEMENT_TAB',
  },
  {
    path: '/forecasting/clone-production',
    exact: true,
    component: CloneProductionPage,
    route: PrivateRoute,
    allowedPermission: 'FORECASTING_PROPAGATION_FORECASTED_TAB',
  },
];
