import React, { memo, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import { ProfileDropdown } from './ProfileDropdown';
import logo from '../../assets/images/gg-logo-white.png';
import { UserContext } from '../../providers/UserProvider';
import { profileNav } from '@utils/profileNav';

export const Topbar = memo(({ isMenuOpened, menuToggle }) => {
  const [facilitiesMenuAnchor, setFacilitiesMenuAnchor] = useState(null);
  const { currentUser, facilities, facility, setFacility } =
    useContext(UserContext);

  const ProfileMenus = profileNav(currentUser).children.flatMap(
    ({ name, link, icon, adminOnly }) =>
      !adminOnly || currentUser.is_admin
        ? [{ label: name, redirectTo: link, icon, hasDivider: false }]
        : []
  );

  return (
    <div className="navbar-custom">
      <div className="container-fluid" style={{ height: '100%' }}>
        <ul
          className="list-unstyled topnav-menu float-right mb-0"
          style={{ height: '100%' }}
        >
          <li className="dropdown notification-list mobile-only">
            <Button
              variant="text"
              sx={{ textTransform: 'none' }}
              className="btn btn-link nav-link dropdown-toggle waves-effect waves-light"
              onClick={e => setFacilitiesMenuAnchor(e.currentTarget)}
            >
              {facility ? facility.name : 'Select a facility'}
              <ExpandMoreIcon />
            </Button>

            <Menu
              anchorEl={facilitiesMenuAnchor}
              open={!!facilitiesMenuAnchor}
              onClose={() => setFacilitiesMenuAnchor(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {facilities &&
                facilities.map(f => {
                  return (
                    <MenuItem key={f.id} onClick={() => setFacility(f, true)}>
                      <Typography variant="body2">{f.name}</Typography>
                    </MenuItem>
                  );
                })}
            </Menu>
          </li>

          <li className="dropdown notification-list">
            <Link
              className={classNames('navbar-toggle', 'nav-link', {
                open: isMenuOpened,
              })}
              to="#"
              onClick={menuToggle}
            >
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </Link>
          </li>

          <li
            className="dropdown notification-list desktop-only"
            style={{ height: '100%' }}
          >
            <ProfileDropdown
              profilePic={''}
              menuItems={ProfileMenus}
              username={currentUser.displayName || currentUser.email || 'User'}
            />
          </li>
        </ul>

        <div className="logo-box float-left mb-0">
          <Link to="/" className="logo text-center">
            <span className="logo-lg">
              <img src={logo} alt="" height="50" />
            </span>
            <span className="logo-sm" style={{ width: '30px' }}>
              <img
                src={logo}
                alt=""
                height="24"
                style={{ minHeight: '40px', minWidth: '20px' }}
              />
            </span>
          </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li className="dropdown d-none d-lg-block">
            <Button
              variant="text"
              sx={{ textTransform: 'none' }}
              className="btn btn-link nav-link dropdown-toggle waves-effect waves-light"
              onClick={e => setFacilitiesMenuAnchor(e.currentTarget)}
            >
              {facility ? facility.name : 'Select a facility'}
              <ExpandMoreIcon />
            </Button>

            <Menu
              anchorEl={facilitiesMenuAnchor}
              open={!!facilitiesMenuAnchor}
              onClose={() => setFacilitiesMenuAnchor(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {facilities &&
                facilities.map(f => {
                  return (
                    <MenuItem key={f.id} onClick={() => setFacility(f, true)}>
                      <Typography variant="body2">{f.name}</Typography>
                    </MenuItem>
                  );
                })}
            </Menu>
          </li>
        </ul>
      </div>
    </div>
  );
});

Topbar.displayName = 'Topbar';
