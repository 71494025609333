import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { createThemeObject } from '@services/theme';

const lightTheme = createThemeObject();
const darkTheme = createThemeObject('dark');
const darkThemeRoutes = [
  '/performance/processing',
  '/inventory/projected-product-inventory',
];

export const ThemeWrapper = ({ children }) => {
  const location = useLocation();
  const theme = useMemo(() => {
    if (darkThemeRoutes.includes(location.pathname)) {
      return darkTheme;
    }
    return lightTheme;
  }, [location]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
