import { authRoutes } from './auth';
import { configurationsRoutes } from './configurations';
import { overviewsRoutes } from './overviews';
import { trendsRoutes } from './trends';
import { planningRoutes } from './planning';
import { forecastingRoutes } from './forecasting';
import { miscRoutes } from './misc';
import { tasksRoutes } from './tasks';
import { recipesRoutes } from './recipes';
import { inventoryRoutes } from './inventory';
import { performanceRoutes } from './performance';
import { logsRoutes } from './logs';

export const routes = [
  ...authRoutes,
  ...configurationsRoutes,
  ...overviewsRoutes,
  ...trendsRoutes,
  ...planningRoutes,
  ...forecastingRoutes,
  ...miscRoutes,
  ...tasksRoutes,
  ...recipesRoutes,
  ...inventoryRoutes,
  ...performanceRoutes,
  ...logsRoutes,
];
