import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { RolesService as service } from '../services';
import { QUERY_KEYS } from '../constants';

export const useRolesStore = params => {
  const queryClient = useQueryClient();

  const { data: rolesData, isLoading: rolesLoading } = useQuery({
    queryKey: [...QUERY_KEYS.ROLES, { params }],
    queryFn: () => service.list(params),
  });

  const { mutateAsync: addRole, isLoading: addRoleInProgress } = useMutation({
    mutationFn: payload => service.add(payload),
    onSuccess: (_, data) => {
      queryClient.invalidateQueries([...QUERY_KEYS.ROLES, { params }]);
    },
  });

  const { mutateAsync: editRole, isLoading: editRoleInProgress } = useMutation({
    mutationFn: payload => service.update(payload),
    onSuccess: (_, data) => {
      queryClient.setQueryData(
        [...QUERY_KEYS.ROLES, { params }],
        (cache = []) => {
          return cache.map(role => (role.id === data.id ? data : role));
        }
      );
    },
  });

  const { mutateAsync: deleteRole, isLoading: deleteRoleInProgress } =
    useMutation({
      mutationFn: payload => service.delete(payload),
      onSuccess: (_, { id }) => {
        queryClient.setQueryData(
          [...QUERY_KEYS.ROLES, { params }],
          (cache = []) => {
            return cache.filter(role => role.id !== id);
          }
        );
      },
    });

  return {
    rolesData,
    rolesOptions: (rolesData || []).map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    rolesLoading,
    addRole,
    addRoleInProgress,
    deleteRole,
    deleteRoleInProgress,
    editRole,
    editRoleInProgress,
  };
};
